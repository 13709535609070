import Swiper, { Autoplay, Navigation } from 'swiper';
Swiper.use([Autoplay, Navigation]);

// eslint-disable-next-line no-unused-vars
let speakersCarrousel;

window.addEventListener('load', function () {
	speakersCarrousel = new Swiper('.tlw__carrousel-speakers', {
		slidesPerView: 1,
		spaceBetween: 20,
		watchOverflow: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			500: {
				slidesPerView: 2,
				spaceBetween: 30,
			},
			768: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 'auto',
				spaceBetween: 50,
			},
		},
	});
});
